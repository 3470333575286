<i18n>
  {
    "en": {
      "title": "Total Logged Hours"
    },
    "th": {
      "title": "เวลาทั้งหมด"
    }
  }
</i18n>

<template>
  <InfoCard :title="$t('title')" :text="total"></InfoCard>
</template>

<script>
import InfoCard from "@/components/InfoCard.vue";

export default {
  components: { InfoCard },
  props: {
    items: Array,
  },
  computed: {
    total() {
      return this.items
        .reduce((acc, cur) => {
          return parseFloat((acc + cur.duration).toFixed(2));
        }, 0)
        .toString();
    },
  },
};
</script>

<style>
</style>
<template>
  <div id="app">
    <v-app>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss">
#app {
  background-color: #fafafa;
}
</style>

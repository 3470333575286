import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main/index.vue";
import Login from "../views/Login.vue";
import Home from "../views/Main/Home.vue";
import Dashboard from "../views/Main/Dashboard.vue";
import Ndt from "../views/Main/ndt/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Main,
    beforeEnter: (to, from, next) => {
      const token = sessionStorage.getItem("logbook-token");
      if (!token) return next("/login");
      next();
    },
    children: [
      { path: "/", name: "Main", component: Home },
      { path: "/dashboard", name: "Dashboard", component: Dashboard },
      { path: "/ndt-practical-training", name: "NDT", component: Ndt },
      {
        path: "/settings",
        name: "Settings",
        component: () => import("../views/Main/Settings/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      document.title = "Logbook";
      sessionStorage.clear();
      next();
    },
  },
  {
    path: "/policy",
    name: "policy",
    component: () => import("../views/Main/policy/index.vue"),
    beforeEnter: (to, from, next) => {
      document.title = "Privacy Policy";
      sessionStorage.clear();
      next();
    },
  },
];

const mode = process.env.NODE_ENV == "development" ? "hash" : "history";

const router = new VueRouter({
  mode,
  routes,
});

export default router;

<i18n>
  {
    "en": {
      "Task": "Task",
      "Experience": "Experience",
      "Privilege": "Privileges"
    },
    "th": {
      "Task": "งาน",
      "Experience": "ประสบการณ์",
      "Privilege": "สิทธิ์"
    }
  }
</i18n>

<template>
  <v-card class="rounded-lg">
    <v-toolbar dense flat class="">
      <v-menu offset-y>
        <template #activator="{ on, value }">
          <v-chip label outlined color="primary" v-on="on">
            {{ $t(criteria.text) }}
            <v-icon right>
              {{ value ? "arrow_drop_up" : "arrow_drop_down" }}
            </v-icon>
          </v-chip>
        </template>
        <v-list dense>
          <v-list-item
            v-for="type in criterias"
            :key="type.value"
            @click="criteria = type"
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t(type.text) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text>
      <canvas id="expTypeChart" :width="width" :height="height"></canvas>
    </v-card-text>
  </v-card>
</template>

<script>
import Chart from "chart.js/auto";
import "chartjs-adapter-moment";

export default {
  props: { items: Array, width: [String, Number], height: [String, Number] },
  data: () => ({
    chart: null,
    criteria: { text: "Task", value: "taskType" },
    criterias: [
      { text: "Task", value: "taskType" },
      { text: "Experience", value: "experienceType" },
      { text: "Privilege", value: "privilege" },
    ],
  }),
  watch: {
    items() {
      this.drawChart();
    },
    criteria() {
      this.drawChart();
    },
  },
  methods: {
    drawChart() {
      if (this.chart) this.chart.destroy();

      const ctx = document.getElementById("expTypeChart");

      const expTypes = [
        ...new Set(this.items.map((x) => x[this.criteria.value])),
      ];

      const colors = ["#3674C9", "#546E93", "#22487D", "#7397C9", "#142A4A"];

      const backgroundColor = colors;

      const cfg = {
        type: "doughnut",
        data: {
          labels: expTypes,
          datasets: [
            {
              data: this.getTime(expTypes),
              backgroundColor,
              hoverOffset: 5,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          // radius: "80%",
        },
      };

      this.chart = new Chart(ctx, cfg);
    },
    getTime(types) {
      const array = types.map((x) => {
        return { x, y: 0 };
      });
      this.items.forEach((x) => {
        const index = array.findIndex((y) => y.x == x[this.criteria.value]);
        array[index].y += x.duration;
      });
      const sum = array.reduce((acc, cur) => {
        return (acc += cur.y);
      }, 0);
      const percent = array.map((x) => {
        return ((x.y * 100) / sum).toFixed(2);
      });
      return percent;
    },
  },
};
</script>

<style>
</style>
export const taskTypes = [
  "Testing",
  "Disassembling",
  "Cleaning",
  "Inspection",
  "Repair",
  "Assembling",
  // { text: "Functional Operation Test", value: "FOT" },
  // { text: "Servicing Ground Handling", value: "SGH" },
  // { text: "Removal Installation Activation", value: "R/I" },
  // { text: "Trouble Shooting Exercise", value: "T/S" },
  // { text: "Operational Check", value: "OPC" },
  // { text: "Replacement", value: "REP" },
  // { text: "Inspection", value: "INSP" },
];

<i18n>
  {
    "en": {
      "entry": "Logbook Entry",
      "reload": "Refresh",
      "headers": {
        "date": "Date",
        "location": "Location",
        "componentType": "Component Type",
        "serialNumber": "Serial Number",
        "taskType": "Task Type",
        "experienceType": "Experience Type",
        "ATA": "ATA",
        "operationPerformed": "Operation",
        "duration": "Duration (hrs)",
        "actions": "Actions"
      }
    },
    "th": {
      "entry": "สร้างบันทึกเวลาใหม่",
      "reload": "รีโหลด",
      "headers": {
        "date": "วันที่",
        "location": "สถานที่",
        "componentType": "ชิ้นงาน",
        "serialNumber": "ซีเรียลนัมเบอร์",
        "taskType": "ประเภทงาน",
        "experienceType": "ประเภทประสบการณ์",
        "ATA": "ATA",
        "operationPerformed": "งานที่ทำ",
        "duration": "ระยะเวลา (ชั่วโมง)",
        "actions": "เมนู"
      }
    }
  }
</i18n>

<template>
  <div class="home" v-resize="onResize">
    <v-container fluid>
      <v-row>
        <v-col>
          <LogbookForm @onAdd="populate"> </LogbookForm>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="rounded-xl">
            <v-card-title>
              <v-row dense>
                <v-col cols="auto">
                  <v-btn rounded depressed :icon="isMobile" @click="populate">
                    <v-icon :left="!isMobile">refresh</v-icon>
                    <span v-if="!isMobile">{{ $t("reload") }}</span>
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <PrintLogbook depressed rounded></PrintLogbook>
                </v-col>
                <v-spacer></v-spacer>
                <v-col :cols="isMobile ? 8 : 4">
                  <v-text-field
                    :label="$t('filter')"
                    single-line
                    outlined
                    v-model="search"
                    dense
                    rounded
                    hide-details
                    clearable
                    append-icon="search"
                    @keyup.enter="commenceSearch(0)"
                    @click:clear="clearSearch"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              disable-sort
              disable-filtering
              :headers="headers"
              :items="items"
              :loading="loading"
              :height="height"
              :mobile-breakpoint="0"
              :loader-height="2"
              :footerProps="footerProps"
              fixed-header
              :options.sync="options"
              :server-items-length="totalItems"
            >
              <template #item.operationPerformed="{ item }">
                <span>{{ truncate(item.operationPerformed) }}</span>
              </template>
              <template #item.duration="{ item }">
                <span>{{ item.duration.toFixed(2) }}</span>
              </template>
              <template #header.actions>
                <v-btn icon :small="small">
                  <v-icon :small="small">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <template #item.actions="{ item }">
                <OptionMenu
                  :small="small"
                  :item="item"
                  @onDelete="populate"
                  @onUpdate="populate"
                ></OptionMenu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LogbookForm from "@/components/LogbookForm.vue";
import OptionMenu from "@/components/OptionMenu.vue";
import PrintLogbook from "@/components/PrintLogbook.vue";

import axios from "axios";
import { capitalize } from "@/myFunctions";
import moment from "moment";

export default {
  name: "Home",
  components: {
    LogbookForm,
    OptionMenu,
    PrintLogbook,
  },
  data: () => ({
    show: false,
    loading: false,
    search: null,
    height: 0,
    footerProps: {
      "items-per-page-options": [10, 50, 100],
      "show-first-last-page": false,
    },
    small: false,
    items: [],
    totalItems: 0,
    options: {
      page: 1,
      itemsPerPage: 50,
      sortBy: ["date"],
      sortDesc: [true],
    },
    cursors: [],
    currentPage: 1,
    showingSearch: false,
  }),
  created() {
    this.populate();
  },
  // async beforeRouteEnter(to, from, next) {
  //   const data = await getData();
  //   next((vm) => {
  //     vm.setData(data);
  //   });
  // },
  mounted() {
    this.onResize();
  },
  watch: {
    options: {
      handler() {
        this.optionHander();
      },
      deep: true,
    },
  },
  methods: {
    optionHander() {
      if (this.showingSearch) this.commenceSearch(this.options.page - 1);
      else this.populate();
    },
    async populate(force = false) {
      if (force) this.options.page = 1;
      this.loading = true;
      this.showingSearch = false;
      this.items = [];

      const token = sessionStorage.getItem("logbook-token");

      const params = {
        itemsPerPage: this.options.itemsPerPage,
        cursor: this.cursors[this.options.page - 2]?.endCursor,
      };

      const { data } = await axios.get("/api/logbook", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });

      const u = JSON.parse(atob(token.split(".")[1]));
      document.title = capitalize(u.name) + " - Logbook";
      data.items.forEach((x) => {
        x.timestamp = moment(x.timestamp).valueOf();
      });

      this.items = data.items;
      this.totalItems = data.total;

      const info = Object.assign({}, data.info, {
        ts: moment(data.info.timestamp).toDate(),
      });

      const dupIndex = this.cursors.findIndex(
        (x) => x.endCursor == info.endCursor
      );
      if (dupIndex > -1) this.cursors.splice(dupIndex, 1);

      this.cursors.splice(this.options.page - 1, 0, info);

      this.loading = false;
    },
    async commenceSearch(page = 0) {
      if (!this.search) return this.populate(true);

      this.showingSearch = true;
      this.loading = true;
      this.items = [];
      const token = sessionStorage.getItem("logbook-token");

      const { data } = await axios.post(
        "/api/search",
        {
          query: this.search,
          itemsPerPage: this.options.itemsPerPage,
          page,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      this.items = data.hits;
      this.totalItems = data.nbHits;
      this.loading = false;
    },
    clearSearch() {
      this.showingSearch = false;
      this.items = [];
      this.totalItems = 0;
      this.search = "";
      this.options.page = 1;
      this.populate();
    },
    onResize() {
      const height = window.innerHeight - 230;
      this.height = !this.isMobile ? `${height}px` : `${height - 48}px`;
    },
    truncate(text) {
      if (!text) return "";
      return text.length > 50 ? `${text.substring(0, 50)}...` : text;
    },
  },
  computed: {
    headers() {
      if (!this.isMobile)
        return [
          { text: "Date", value: "date", divider: true },
          { text: "Location", value: "location", divider: true },
          { text: "Component Type", value: "componentType" },
          { text: "S/N", value: "serialNumber" },
          { text: "ATA", value: "ata" },
          { text: "Rating", value: "rating", divider: true },
          { text: "Privilege", value: "privilege" },
          { text: "Task Type", value: "taskType" },
          { text: "Experience Type", value: "experienceType", divider: true },
          { text: "Operation", value: "operationPerformed", divider: true },
          {
            text: "Duration (hrs)",
            value: "duration",
            align: "right",
            divider: true,
          },
          { text: "Jobnumber", value: "jobnumber", divider: true },
          { text: "Actions", value: "actions", divider: true, sortable: false },
          // { text: "Track", value: "track" },
          // { text: "User", value: "user.name" },
        ];
      return [
        { text: "Date", value: "date", divider: true },
        { text: "S/N", value: "serialNumber", divider: true },
        { text: "Duration (hrs)", value: "duration", divider: true },
        { text: "Actions", value: "actions", divider: true, sortable: false },
      ];
    },
    total() {
      return this.items.reduce((acc, cur) => {
        acc += cur.duration;
        return acc;
      }, 0);
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="localShow"
    width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
  >
    <v-card class="rounded-xl" :loading="loading" :disabled="loading">
      <v-toolbar
        :dense="dense"
        flat
        class="transparent"
        v-if="mode == 'create'"
      >
        <h3>New Record</h3>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar :dense="dense" flat class="transparent" v-if="mode == 'edit'">
        <h4>Edit Record #{{ logId }}</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-img
        :src="require('@/assets/undraw_book_lover_re_rwjy.svg')"
        :height="isMobile ? 100 : 200"
        contain
      ></v-img> -->
      <!-- <v-card-title></v-card-title> -->
      <v-card-text>
        <v-form ref="myForm">
          <v-container fluid>
            <v-row :dense="dense">
              <v-col cols="6">
                <v-menu
                  offset-y
                  transition="slide-y-transition"
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :dense="dense"
                      v-model="date"
                      readonly
                      label="Date"
                      v-bind="attrs"
                      v-on="on"
                      :rules="[required]"
                      hide-details
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker no-title v-model="date"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row :dense="dense">
              <v-col>
                <v-select
                  outlined
                  :dense="dense"
                  :items="methods"
                  v-model="method"
                  label="Method"
                  :rules="[required]"
                  hide-details
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  outlined
                  :dense="dense"
                  :items="levels"
                  v-model="level"
                  label="Level"
                  :rules="[required]"
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-row :dense="dense">
              <v-col cols="12">
                <JobnumberSearchField
                  outlined
                  :dense="dense"
                  v-model="jobnumber"
                  @found="foundJob"
                  @notfound="notFoundJob"
                  hide-details
                ></JobnumberSearchField>
              </v-col>
            </v-row>
            <v-row :dense="dense">
              <v-col cols="6">
                <v-text-field
                  outlined
                  :dense="dense"
                  v-model="partNumber"
                  label="Part number"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  :dense="dense"
                  v-model="serialNumber"
                  label="Serial number"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row :dense="dense">
              <v-col>
                <v-textarea
                  outlined
                  :dense="dense"
                  v-model="workPerformed"
                  auto-grow
                  :rows="5"
                  label="Work performed"
                  :rules="[required]"
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row :dense="dense">
              <v-col cols="6">
                <v-text-field
                  outlined
                  :dense="dense"
                  type="number"
                  v-model.number="duration"
                  label="Duration"
                  prepend-inner-icon="timer"
                  :rules="[required]"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              :dense="dense"
              v-if="mode == 'create' || (mode == 'edit' && !alreadyHasFile)"
            >
              <v-col>
                <v-file-input
                  outlined
                  :dense="dense"
                  accept="application/pdf"
                  label="NDT report"
                  prepend-inner-icon="attach_file"
                  prepend-icon=""
                  show-size
                  v-model="file"
                  hide-details
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-row :dense="dense" v-else>
              <v-col>
                <v-card class="rounded-lg" @click="openFile">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary"> insert_drive_file </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>{{ ndtReport }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="delFile">
                        <v-icon>delete_outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn rounded text @click="cancel"> cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded color="primary" @click="submit">
          <v-icon left>add</v-icon>
          submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const levels = [1, 2, 3];

import moment from "moment";
import JobnumberSearchField from "@/components/JobnumberSearchField.vue";
import methods from "./methods";
import axios from "axios";

export default {
  components: { JobnumberSearchField },
  props: {
    show: Boolean,
    mode: {
      type: String,
      default: "create",
    },
    logId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    methods,
    levels,
    date: moment().format("YYYY-MM-DD"),
    method: null,
    level: null,
    jobnumber: null,
    partNumber: null,
    serialNumber: null,
    workPerformed: null,
    duration: null,
    ndtReport: null,
    instructor: null,
    required: (v) => !!v || "Required.",
    file: null,
    alreadyHasFile: false,
    dense: false,
  }),
  watch: {
    localShow(v) {
      this.alreadyHasFile = false;
      if (!v || this.mode == "create") return;
      this.populate();
    },
  },
  methods: {
    async populate() {
      this.loading = true;
      const { data } = await axios.get(`/api/ndt/${this.logId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("logbook-token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data);
      console.log(this.$data);
      for (let key in data) {
        if (key == "ndtReport") {
          if (data[key]) {
            this.alreadyHasFile = true;
          }
        }
        this.$data[key] = data[key];
      }
      this.loading = false;
    },
    delFile() {
      this.alreadyHasFile = false;
      this.ndtReport = null;
    },
    getFileName(name) {
      if (!name) return "";
      return name.split("/").pop();
    },
    openFile() {
      window.open(this.ndtReport, "_blank");
    },
    async submit() {
      if (!this.$refs.myForm.validate()) return;
      this.loading = true;
      const post = {
        date: this.date,
        method: this.method,
        level: this.level,
        jobnumber: this.jobnumber,
        partNumber: this.partNumber,
        serialNumber: this.serialNumber,
        workPerformed: this.workPerformed,
        duration: this.duration,
        instructor: this.instructor,
        ndtReport: this.ndtReport,
      };

      const formData = new FormData();
      formData.append("data", JSON.stringify(post));

      if (this.file) {
        formData.append("file", this.file);
      }

      const method = this.mode == "create" ? "post" : "put";
      const url = this.mode == "create" ? "/api/ndt" : "/api/ndt/" + this.logId;

      await axios({
        method,
        url,
        data: formData,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("logbook-token")}`,
          "Content-Type": "multipart/form-data",
        },
      });

      this.$refs.myForm.reset();
      this.localShow = false;
      this.$emit("updated");
      this.loading = false;
    },
    cancel() {
      this.$refs.myForm.reset();
      this.localShow = false;
    },
    foundJob(data) {
      console.log(data);
      this.partNumber = data.partnumber;
      this.serialNumber = data.serialnumber1;
    },
    notFoundJob() {
      this.partNumber = "";
      this.serialNumber = "";
    },
  },
  computed: {
    localShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isUrl() {
      if (!this.ndtReport) return true;
      const regex =
        /[(http(s)?)://(www.)?a-zA-Z0-9@:%._\-+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

      return regex.test(this.ndtReport) || "Please enter a valid URL.";
    },
  },
};
</script>

<style>
</style>
<template>
  <v-text-field
    :label="$t('jobnumber')"
    :dense="dense"
    :hide-details="hideDetails"
    :outlined="outlined"
    :solo="solo"
    :loading="loading"
    :value="value"
    @input="$emit('input', $event)"
    @keyup="submit"
    append-icon="search"
  ></v-text-field>
</template>

<script>
import axios from "axios";

export default {
  props: {
    value: String,
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
  }),
  watch: {
    value(v) {
      if (v && v.substring(0, 1).toUpperCase() != "A") {
        v = "ABS" + v;
      }
      if (v) {
        v = v.toUpperCase();
      }
      this.$emit("input", v);
    },
  },
  methods: {
    async submit() {
      if (this.value.length !== 10) return;
      this.loading = true;
      const { data } = await axios.get(`/api/jobnumber/${this.value}`);
      this.loading = false;
      if (!data) {
        this.$emit("input", "");
        this.$emit("notfound");
        return;
      }
      this.$emit("found", data);
    },
  },
};
</script>

<style>
</style>
<i18n>
  {
    "en": {
      "logout": "Logout",
      "menu": {
        "title": "My Logbook"
      },
      "logoutConfirm": "Are you sure you want to logout?"
    },
    "th": {
      "logout": "ออกจากระบบ",
      "menu": {
        "title": "บันทึกเวลาของฉัน"
      },
      "logoutConfirm": "ท่านต้องการออกจากระบบหรือไม่?"
    }
  }
</i18n>

<template>
  <span>
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="pt-4"
      :color="isMobile ? 'white' : 'transparent'"
      v-if="!loginPage"
      shaped
    >
      <v-list dense nav>
        <v-list-item>
          <div class="title">{{ firstName }}</div>
        </v-list-item>
      </v-list>
      <v-list dense nav shaped class="pl-0">
        <v-list-item
          v-for="(menu, index) in menus"
          :key="index"
          link
          :to="menu.to"
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(menu.title) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense nav id="logout">
        <v-list-item @click="showLogoutConfirm = true">
          <v-list-item-icon>
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("logout") }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app fixed dark dense v-if="isMobile" color="primary">
      <v-app-bar-nav-icon @click="drawer = !drawer"> </v-app-bar-nav-icon>
      <span class="title" v-if="isMobile">{{ firstName }}</span>
    </v-app-bar>
    <v-dialog v-model="showLogoutConfirm" width="300" persistent>
      <v-card :disabled="loading" :loading="loading" class="rounded-lg">
        <v-toolbar dense flat>
          <h4>{{ $t("logout") }}</h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="showLogoutConfirm = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-img :src="require('@/assets/undraw_login_re_4vu2.svg')"></v-img>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            {{ $t("logoutConfirm") }}
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showLogoutConfirm = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded color="secondary" @click="logout">
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  components: {},
  data: () => ({
    drawer: true,
    menus: [
      // { title: "Dashboard", icon: "dashboard", to: "/dashboard" },
      { title: "logbook", icon: "book", to: "/" },
      {
        title: "NDT Practical Training",
        icon: "biotech",
        to: "/ndt-practical-training",
      },
      { title: "settings", icon: "settings", to: "/settings" },
    ],
    user: null,
    loading: false,
    showLogoutConfirm: false,
  }),
  created() {
    this.getUser();
    this.drawer = !this.isMobile;
  },
  watch: {
    // loginPage(v) {
    //   if (!v) {
    //     this.getUser();
    //   } else {
    //     this.user = null;
    //   }
    // },
  },
  methods: {
    getUser() {
      this.user = null;
      const user = sessionStorage.getItem("logbook-token");
      this.user = JSON.parse(atob(user.split(".")[1]));
    },
    logout() {
      this.loading = true;
      sessionStorage.clear();
      setTimeout(() => {
        this.$router.replace("/login");
      }, 1000);
    },
  },
  computed: {
    firstName() {
      return this.user?.name?.split(" ")[0] || "";
    },
    loginPage() {
      return this.$route.name == "Login";
    },
  },
};
</script>

<style>
#logout {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>
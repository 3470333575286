<template>
  <div id="langSelect">
    <div
      v-for="l in items"
      :key="l.value"
      @click="setLang(l)"
      class="selection body-2"
      :class="{
        'text--primary font-weight-bold': isLangSelected(l.value),
        'text--disabled': !isLangSelected(l.value),
      }"
    >
      {{ l.text }}
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      { text: "Eng", value: "en" },
      { text: "ไทย", value: "th" },
    ],
    lang: null,
  }),
  created() {
    this.lang = localStorage.getItem("language") || "en";
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
      this.$vuetify.lang.current = val;
      localStorage.setItem("language", val);
    },
  },
  methods: {
    setLang(lang) {
      this.lang = lang.value;
    },
    isLangSelected(lang) {
      return this.lang === lang;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #282828;

#langSelect {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
}

.selection {
  padding: 5px;
  width: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    //make text lighter
    color: $primary !important;
  }
}
</style>

<template>
  <v-autocomplete
    outlined
    :dense="dense"
    label="Task Type"
    :items="taskTypes"
    v-model="_value"
    clearable
  ></v-autocomplete>
</template>

<script>
import { taskTypes } from "./taskTypes.js";

export default {
  props: {
    value: String,
    dense: Boolean,
  },
  computed: {
    taskTypes() {
      return taskTypes;
    },
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
</style>
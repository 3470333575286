<template>
  <div>
    <v-progress-linear
      indeterminate
      color="pink"
      absolute
      :active="active"
      style="z-index: 9999"
    ></v-progress-linear>
    <Nav></Nav>
    <LangSelect></LangSelect>
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import LangSelect from "@/components/LangSelect.vue";

export default {
  components: { Nav, LangSelect },
  computed: {
    active() {
      return false;
    },
  },
};
</script>

<style>
</style>
<i18n>
  {
    "en": {
      "privilege" : "Privilege"
    },
    "th": {
      "privilege" : "สิทธิ"
    }
  }
</i18n>

<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="image"
      height="40"
    ></v-skeleton-loader>
    <v-combobox
      v-else
      :items="privileges"
      v-model="localPrivilege"
      :label="$t('privilege')"
      outlined
      :dense="dense"
      hide-details
      clearable
      :loading="loading"
    ></v-combobox>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["privilege", "dense"],
  data: () => ({
    loading: false,
    privileges: ["ET", "MT", "PT", "RT", "UT"],
  }),
  created() {
    this.populate();
  },
  methods: {
    async populate() {
      this.loading = true;
      const { data } = await axios.get("/api/logbook/privileges", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("logbook-token")}`,
        },
      });
      this.loading = false;
      this.privileges = data.map((x) => x.name);
    },
  },
  computed: {
    localPrivilege: {
      get() {
        return this.privilege;
      },
      set(v) {
        this.$emit("update:privilege", v);
      },
    },
  },
};
</script>

<style>
</style>
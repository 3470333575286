<template>
  <div>
    <v-menu offset-y transition="slide-y-transition" rounded="xl">
      <template #activator="{ on }">
        <v-btn icon :small="small" v-on="on">
          <v-icon :small="small">more_vert</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="showMod">
          <v-list-item-icon>
            <v-icon>edit</v-icon>
          </v-list-item-icon>
          <v-list-item-title> {{ $t("modify") }} </v-list-item-title>
        </v-list-item>
        <v-list-item @click="del(item)">
          <v-list-item-icon>
            <v-icon>delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title> {{ $t("delete") }} </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <ConfirmDialog ref="delDialog"></ConfirmDialog>
    <LogbookEdit
      :show.sync="show"
      :item="item"
      @onUpdate="$emit('onUpdate')"
    ></LogbookEdit>
  </div>
</template>

<script>
import axios from "axios";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import LogbookEdit from "@/components/LogbookEdit.vue";

export default {
  components: { ConfirmDialog, LogbookEdit },
  props: { item: Object, small: Boolean },
  data: () => ({ show: false }),
  methods: {
    async del() {
      const options = {
        validation: {
          required: false,
        },
      };
      if (!(await this.$refs.delDialog.confirmed(options))) return;
      await axios.delete(`/api/logbook/${this.item.id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("logbook-token")}`,
        },
      });
      this.$emit("onDelete");
    },
    async showMod() {
      this.show = true;
    },
  },
};
</script>

<style>
</style>
<i18n>
  {
    "en": {
      "entry": "Entry",
      "addEntry": "Logbook Entry",
      "newEntry": "New Logbook Entry",
      "date": "Date of work",
      "location": "Location",
      "componentType": "Component type",
      "serialNumber": "Serial number",
      "taskType": "Task type",
      "experienceType": "Experience type",
      "ATA":"ATA",
      "duration": "Duration",
      "hours": "hours",
      "operationPerformed": "Operation performed",
      "remark": "Remark",
      "timer": "Timer",
      "privilege" : "Privilege"
    },
    "th": {
      "entry": "บันทึกที่",
      "addEntry": "สร้างบันทึกเวลาใหม่",
      "newEntry": "สร้างบันทึกใหม่",
      "date": "วันที่ปฏิบัติงาน",
      "location": "สถานที่",
      "componentType": "ชิ้นส่วน",
      "serialNumber": "ซีเรียลนัมเบอร์",
      "taskType": "ประเภทกิจกรรม",
      "experienceType": "ประเภทประสบการณ์",
      "ATA":"ATA",
      "duration": "ระยะเวลา",
      "hours": "ชั่วโมง",
      "operationPerformed": "รายละเอียดงานที่ทำ",
      "remark": "หมายเหตุ",
      "timer": "จับเวลา",
      "privilege" : "สิทธิ"
    }
  }
</i18n>

<template>
  <div>
    <slot name="activator" :show="toggle">
      <v-btn large color="secondary" rounded @click="toggle">
        <v-icon left>add</v-icon>
        {{ $t("addEntry") }}
      </v-btn>
    </slot>
    <v-dialog
      v-model="show"
      width="500"
      scrollable
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="loading" :disabled="loading" class="rounded-xl">
        <v-toolbar :dense="dense" color="transparent" flat>
          <v-icon left color="primary">add</v-icon>
          <h3>{{ $t("newEntry") }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="show = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-img
          :src="require('@/assets/undraw_book_lover_re_rwjy.svg')"
          :height="isMobile ? 100 : 200"
          contain
        ></v-img> -->
        <v-card-title></v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container fluid>
              <v-row :dense="dense">
                <v-col cols="12">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="date"
                        :label="$t('date')"
                        append-icon="today"
                        readonly
                        outlined
                        :dense="dense"
                        v-on="on"
                        hide-details
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      scrollable
                      class="rounded-lg"
                      no-title
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(date)"
                      >
                        {{ $t("save") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid>
              <v-row :dense="dense">
                <v-col cols="12">
                  <JobnumberSearchField
                    outlined
                    :dense="dense"
                    hide-details
                    v-model="jobnumber"
                    @found="found"
                  ></JobnumberSearchField>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    :label="$t('componentType')"
                    v-model="componentType"
                    outlined
                    :dense="dense"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :label="$t('serialNumber')"
                    v-model="serialNumber"
                    outlined
                    :dense="dense"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    :label="$t('ATA')"
                    v-model="ata"
                    outlined
                    :dense="dense"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid>
              <v-row :dense="dense">
                <v-col cols="12">
                  <v-text-field
                    :label="$t('location')"
                    v-model="location"
                    outlined
                    :dense="dense"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid>
              <v-row :dense="dense">
                <v-col cols="6" md="8">
                  <v-text-field
                    :label="$t('duration')"
                    v-model.number="duration"
                    outlined
                    :dense="dense"
                    hide-details
                    :suffix="$t('hours')"
                    :rules="[rules.required, rules.halfHour]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-btn color="secondary" block @click="timer = true" disabled>
                    <v-icon :left="!isMobile">timer</v-icon>
                    <span v-if="!isMobile">{{ $t("timer") }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid>
              <v-row :dense="dense">
                <v-col>
                  <Privilege
                    :dense="dense"
                    :privilege.sync="privilege"
                  ></Privilege>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid>
              <v-row :dense="dense">
                <v-col cols="6">
                  <v-combobox
                    :label="$t('taskType')"
                    v-model="taskType"
                    :items="taskTypes"
                    outlined
                    :dense="dense"
                    :rules="[rules.required]"
                  ></v-combobox>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    :label="$t('experienceType')"
                    v-model="experienceType"
                    :items="experienceTypes"
                    outlined
                    :dense="dense"
                    hide-details
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    :label="$t('operationPerformed')"
                    v-model="operationPerformed"
                    outlined
                    :dense="dense"
                    hide-details
                    :rows="3"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    :label="$t('remark')"
                    v-model="remark"
                    outlined
                    :dense="dense"
                    hide-details
                    :rows="3"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn rounded text @click="cancel">
            <v-icon left>cancel</v-icon>
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded text @click="reset">
            <v-icon left>undo</v-icon>
            {{ $t("clear") }}
          </v-btn>
          <v-btn rounded color="secondary" @click="post">
            <v-icon left>save</v-icon>
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <Duration :show.sync="timer" @updated="duration = $event"></Duration>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import Duration from "@/components/Duration.vue";
import JobnumberSearchField from "@/components/JobnumberSearchField.vue";
import Privilege from "@/components/Privilege.vue";

import { taskTypes } from "./taskTypes.js";

export default {
  components: { Duration, JobnumberSearchField, Privilege },
  props: {},
  data: () => ({
    show: false,
    modal: false,
    loading: false,
    date: moment().format("YYYY-MM-DD"),
    location: "Airborne Support",
    componentType: "",
    serialNumber: "",
    rating: "",
    privilege: "",
    taskType: "",
    experienceType: "",
    ata: "",
    operationPerformed: "",
    duration: 0,
    remark: "",
    jobnumber: "",
    rules: {
      required: (v) => !!v || "Required.",
      halfHour: (v) => v % 0.5 == 0 || "Invalid.",
    },
    timer: false,
    dense: false,
  }),
  watch: {
    show(v) {
      if (!v) this.reset();
    },
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    reset() {
      this.$refs.form.reset();
      setTimeout(() => {
        this.date = moment().format("YYYY-MM-DD");
        this.location = "Airborne Support";
      }, 0);
    },

    async post() {
      if (!this.$refs.form.validate()) return;

      const post = {
        date: this.date,
        location: this.location,
        componentType: this.componentType,
        serialNumber: this.serialNumber,
        rating: this.rating,
        privilege: this.privilege,
        taskType: this.taskType,
        experienceType: this.experienceType,
        ata: this.ata,
        operationPerformed: this.operationPerformed,
        duration: this.duration,
        remark: this.remark,
        jobnumber: this.jobnumber,
      };

      this.loading = true;

      await axios.post("/api/logbook", post, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("logbook-token")}`,
        },
      });
      this.loading = false;
      this.$emit("onAdd");
      this.show = false;
    },
    found(e) {
      console.log(e);
      this.componentType = e.partname;
      this.serialNumber = toggleDOM(e.serialnumber1);
      this.jobnumber = e.jobnumber;
      this.ata = e.ata;

      function toggleDOM(val) {
        let arr = val.split("-");
        let b = arr[arr.length - 2];
        if (b != undefined && b.length == 1) {
          return arr[arr.length - 2] + "-" + arr[arr.length - 1];
        } else {
          return arr[arr.length - 1];
        }
      }
    },
    cancel() {
      this.reset();
      this.show = false;
    },
  },
  computed: {
    experienceTypes() {
      return [
        { text: "Training", value: "Training" },
        { text: "Perform", value: "Perform" },
        { text: "Supervise", value: "Supervise" },
        { text: "CRS", value: "CRS" },
      ];
    },
    taskTypes() {
      return taskTypes;
    },
  },
};
</script>

<style>
</style>
import Vue from "vue";
import Vuex from "vuex";

import { getData } from "@/myFunctions.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    logbook: [],
  },
  mutations: {
    setLogbook(state, logbook) {
      state.logbook = logbook;
    },
  },
  actions: {
    async setLogbook({ commit }) {
      const items = await getData();
      commit("setLogbook", items);
    },
  },
});

export default store;

<template>
  <v-card class="rounded-lg">
    <v-card-text>
      <div class="caption">{{ title }}</div>
      <div class="main-text primary--text">{{ text }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
    text: String,
  },
};
</script>

<style>
.main-text {
  font-size: 2.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
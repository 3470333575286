<i18n>
  {
    "en": {
      "start": "Start",
      "stop":"Stop"
    },
    "th": {
      "start": "เริ่ม",
      "stop": "หยุด"
    }
  }
</i18n>
<template>
  <v-dialog
    v-model="show"
    width="200"
    persistent
    :overlay-color="isMobile ? 'white' : undefined"
    :overlay-opacity="isMobile ? 1 : undefined"
  >
    <v-card>
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:show', false)">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="ma-0 pa-0">
        <v-container fluid fill-height>
          <v-row justify="center" align="center">
            <v-col class="text-center">
              <div class="clock">{{ clock }}</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-btn
                dark
                block
                :color="counting ? 'red' : 'green'"
                @click="toggle"
              >
                {{ counting ? $t("stop") : $t("start") }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                dark
                outlined
                block
                color="primary"
                :disabled="counting"
                @click="save"
              >
                {{ $t("save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  props: { show: Boolean },
  data: () => ({
    timer: null,
    time: null,
    elapse: 0,
    clock: "00:00:00",
    counting: false,
  }),
  created() {
    this.init();
  },
  watch: {
    elapse() {
      this.clock = this.time.format("HH:mm:ss");
    },
  },
  methods: {
    init() {
      this.time = moment(moment().format("YYYY-MM-DD"));
    },
    save() {
      let v = Math.ceil(this.elapse / 60);
      console.log(v, `${this.elapse / 60} rounded up to 1 min`);
      v /= 60;
      console.log(v);
      v = parseFloat(v.toFixed(2));
      console.log(v);
      this.$emit("updated", v);
      this.$emit("update:show", false);
      this.elapse = 0;
      this.init();
    },
    toggle() {
      this.counting = !this.counting;
      if (this.counting) {
        this.timer = setInterval(() => {
          this.elapse++;
          this.time.add(1, "s");
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
    },
  },
};
</script>

<style>
.clock {
  height: 60px;
  font-size: 38px;
}
</style>
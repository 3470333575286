<template>
  <div class="home">
    <v-container fluid>
      <v-row dense class="mt-8">
        <v-col cols="10">
          <v-row dense>
            <v-col cols="12">
              <Test :items="items"></Test>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-row dense>
            <v-col cols="12">
              <TotalHours :items="items"></TotalHours>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <ExpType :items="items"></ExpType>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getData } from "@/myFunctions.js";
import Test from "./charts/test.vue";
import TotalHours from "./charts/TotalHours.vue";
import ExpType from "./charts/ExpType.vue";

export default {
  components: { Test, TotalHours, ExpType },
  data: () => ({
    items: [],
  }),
  async beforeRouteEnter(to, from, next) {
    const items = await getData();
    next((vm) => vm.setData(items));
  },
  methods: {
    setData(data) {
      this.items = data;
    },
  },
};
</script>

<style>
</style>
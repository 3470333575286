import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

import th from "vuetify/es5/locale/th";
import en from "vuetify/es5/locale/en";

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#000000",
        secondary: "#000000",
      },
    },
  },
  icons: {
    iconfont: "md",
  },
  lang: {
    locales: { en, th },
  },
});

<i18n>
{
  "en": {
    "login": "Login",
    "employeeId": "Employee ID",
    "password": "Password",
    "next": "Next",
    "create_password": "Create password"
  },
  "th": {
    "login": "เข้าสู่ระบบ",
    "employeeId": "รหัสพนักงาน",
    "password": "พาสเวิร์ด",
    "next": "ถัดไป",
    "create_password": "สร้างพาสเวิร์ดใหม่"
  }
}
</i18n>

<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-card class="rounded-lg" :disabled="disabled" width="300">
        <v-toolbar flat dense>
          <h4 class="text-capitalize">Logbook</h4>
        </v-toolbar>
        <v-card-title>
          <v-img
            height="150"
            v-if="tab == 0"
            :src="require('@/assets/undraw_personal_notebook_re_d7dc.svg')"
          ></v-img>
          <v-img
            height="150"
            v-else
            :src="require('@/assets/undraw_forgot_password_re_hxwm.svg')"
          ></v-img>
        </v-card-title>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card-text class="pb-0">
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12">
                    <h4>{{ $t("login") }}</h4>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      autofocus
                      :label="$t('employeeId')"
                      v-model="employeeId"
                      hide-details
                      @keyup.enter="getUser"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                :loading="loading"
                color="primary"
                @click="getUser"
              >
                {{ $t("next") }}
              </v-btn>
            </v-card-actions>
          </v-tab-item>
          <!-- login -->
          <v-tab-item>
            <v-card-text class="pb-0">
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12">
                    <h4>{{ user }}</h4>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" v-if="hasPassword">
                    <v-text-field
                      outlined
                      dense
                      autofocus
                      :label="$t('password')"
                      v-model="password"
                      hide-details
                      :type="visible ? 'text' : 'password'"
                      :append-icon="visible ? 'visibility' : 'visibility_off'"
                      @click:append="visible = !visible"
                      @keyup.enter="login"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn icon @click="tab = 0">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                :loading="loggingIn"
                color="primary"
                @click="login"
              >
                {{ $t("submit") }}
              </v-btn>
            </v-card-actions>
          </v-tab-item>
          <!-- set password -->
          <v-tab-item>
            <v-card-text class="pb-0">
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12">
                    <h4>{{ user }}</h4>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      autofocus
                      :label="$t('create_password')"
                      v-model="password"
                      hide-details
                      :type="visible ? 'text' : 'password'"
                      :append-icon="visible ? 'visibility' : 'visibility_off'"
                      @click:append="visible = !visible"
                      @keyup.enter="register"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn icon @click="tab = 0">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                :loading="loggingIn"
                color="primary"
                @click="register"
              >
                {{ $t("submit") }}
              </v-btn>
            </v-card-actions>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
    <LangSelect></LangSelect>
  </v-container>
</template>

<script>
import axios from "axios";
import LangSelect from "@/components/LangSelect";

export default {
  components: {
    LangSelect,
  },
  data: () => ({
    employeeId: "",
    password: "",
    loading: false,
    loggingIn: false,
    visible: false,
    hasPassword: true,
    tab: 0,
    user: "",
  }),
  watch: {
    visible(val) {
      if (val) {
        setTimeout(() => {
          this.visible = false;
        }, 4000);
      }
    },
    tab(v) {
      if (v == 0) this.reset();
    },
  },
  created() {
    if (this.$route.query.employeeid) {
      this.employeeId = this.$route.query.employeeid;
      this.login();
    }
  },
  methods: {
    async getUser() {
      if (!this.employeeId) return;
      const post = {
        employeeId: this.employeeId,
        password: this.password,
      };
      this.loading = true;
      const { data } = await axios.post("/login/user", post);
      this.loading = false;
      if (data.err) return (this.employeeId = "");
      this.user = data.user;
      if (data.message == "LOGIN") {
        this.tab = 1;
      }
      if (data.message == "REGISTER") {
        this.tab = 2;
      }
    },
    async register() {
      if (!this.password) return;
      const post = {
        employeeId: this.employeeId,
        password: this.password,
      };
      this.loggingIn = true;

      const { data } = await axios.post("/login/register", post);

      if (!data) {
        this.reset();
        this.loggingIn = false;
      } else {
        this.setStorage(data.token);
        this.$router.push("/");
      }
    },
    async login() {
      if (!this.password) return;
      const post = {
        employeeId: this.employeeId,
        password: this.password,
      };

      this.loggingIn = true;

      const { data } = await axios.post("/login", post);

      if (!data) {
        this.reset();
        this.loggingIn = false;
      } else {
        this.setStorage(data.token);
        this.$router.push("/");
      }
    },
    reset() {
      this.employeeId = "";
      this.password = "";
    },
    setStorage(token) {
      sessionStorage.setItem("logbook-token", token);
    },
  },
  computed: {
    disabled() {
      return this.loading || this.loggingIn;
    },
  },
};
</script>

<style>
</style>
<template>
  <v-card class="rounded-lg">
    <v-toolbar dense flat class="">
      <v-btn
        class="ml-1"
        v-for="t in types"
        :key="t.value"
        icon
        small
        @click="type = t"
        :color="type.value == t.value ? 'primary' : ''"
      >
        <v-icon>{{ t.text }}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <canvas id="timelineChart" :width="width" :height="height"></canvas>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import Chart from "chart.js/auto";
import "chartjs-adapter-moment";

export default {
  components: {},
  props: {
    items: Array,
    width: [String, Number],
    height: [String, Number],
  },
  data: () => ({
    types: [
      { text: "bar_chart", value: "bar" },
      { text: "show_chart", value: "line" },
    ],
    type: { text: "bar_chart", value: "bar" },
    chart: null,
    startDate: moment().subtract(3, "month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  }),
  watch: {
    items() {
      this.drawChart();
    },
    type() {
      this.drawChart();
    },
  },
  created() {
    this.drawChart();
  },
  methods: {
    updateChart() {
      this.chart.update();
    },
    drawChart() {
      if (this.chart) this.chart.destroy();
      // if (!this.items.length) return;

      const ctx = document.getElementById("timelineChart");

      const cfg = {
        type: this.type.value,
        data: {
          datasets: [
            {
              label: ["Hours"],
              data: this.getTime(),
              backgroundColor: "#bc5090",
              borderColor: "#bc5090",
              borderWidth: this.type.value == "line" ? 2 : 0,
              barThickness: this.isMobile ? 2 : 4,
              pointRadius: 0,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: "time",
              grid: { display: false },
            },
            y: {
              type: "linear",
              grid: { display: false },
            },
          },
        },
      };

      this.chart = new Chart(ctx, cfg);
    },
    getTime() {
      if (!this.items.length) return;
      const days = [];

      this.items.forEach((x) => {
        let date = moment(x.date).format("YYYY-MM-DD");
        let index = days.findIndex((x) => x.x == date);
        let hours = x.duration;
        if (index < 0) {
          days.push({
            x: date,
            y: hours,
          });
        } else {
          days[index].y += hours;
        }
      });
      days.sort((a, b) => {
        if (moment(a.date).valueOf() < moment(b.date).valueOf()) return -1;
        if (moment(a.date).valueOf() > moment(b.date).valueOf()) return 1;
        return 0;
      });

      const dateArray = [];
      let d = this.startDate;
      while (d <= this.endDate) {
        const day = days.find((x) => x.x == d);
        if (!day) {
          dateArray.push({ x: d, y: 0 });
        } else {
          dateArray.push(days.find((x) => x.x == d));
        }
        d = moment(d).add(1, "day").format("YYYY-MM-DD");
      }
      return dateArray;
    },
  },
};
</script>

<style>
</style>
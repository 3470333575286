<i18n>
  {
    "en": {
      "entry": "Logbook Entry",
      "reload": "Refresh",
      "headers": {
        "date": "Date",
        "location": "Location",
        "componentType": "Component Type",
        "serialNumber": "Serial Number",
        "taskType": "Task Type",
        "experienceType": "Experience Type",
        "ATA": "ATA",
        "operationPerformed": "Operation",
        "duration": "Duration (hrs)",
        "actions": "Actions"
      },
      "print": "Print"
    },
    "th": {
      "entry": "สร้างบันทึกเวลาใหม่",
      "reload": "รีโหลด",
      "headers": {
        "date": "วันที่",
        "location": "สถานที่",
        "componentType": "ชิ้นงาน",
        "serialNumber": "ซีเรียลนัมเบอร์",
        "taskType": "ประเภทงาน",
        "experienceType": "ประเภทประสบการณ์",
        "ATA": "ATA",
        "operationPerformed": "งานที่ทำ",
        "duration": "ระยะเวลา (ชั่วโมง)",
        "actions": "เมนู"
      },
      "print": "พิมพ์บันทึกเวลา"
    }
  }
</i18n>

<template>
  <v-container fluid v-resize="onResize">
    <v-row>
      <v-col>
        <v-btn @click="show = true" rounded color="secondary" large>
          <v-icon left>add</v-icon>add
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="rounded-xl">
          <v-card-title>
            <v-row dense>
              <v-col cols="auto">
                <v-btn rounded depressed :icon="isMobile" @click="populate">
                  <v-icon :left="!isMobile">refresh</v-icon>
                  <span v-if="!isMobile">{{ $t("reload") }}</span>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  rounded
                  depressed
                  :icon="isMobile"
                  @click="showPrint = true"
                >
                  <v-icon :left="!isMobile">print</v-icon>
                  <span v-if="!isMobile">{{ $t("print") }}</span>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col :cols="isMobile ? 8 : 4">
                <v-text-field
                  :label="$t('filter')"
                  outlined
                  v-model="search"
                  dense
                  rounded
                  hide-details
                  clearable
                  append-icon="search"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :loading="loading"
            :mobile-breakpoint="0"
            :loader-height="1"
            :height="height"
            :footerProps="footerProps"
          >
            <template #header.actions>
              <v-btn icon>
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <template #item.timestamp="{ item }">
              {{ timestamp(item.timestamp) }}
            </template>
            <template #item.ndtReport="{ item }">
              <v-btn
                icon
                @click="showReport(item)"
                v-if="item.ndtReport"
                color="primary"
              >
                <v-icon>insert_drive_file</v-icon>
              </v-btn>
            </template>
            <template #item.actions="{ item }">
              <OptionMenu :item="item" @updated="populate"></OptionMenu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <NdtForm :show.sync="show" @updated="populate"></NdtForm>
    <v-dialog v-model="showPrint" width="400" persistent>
      <v-card class="rounded-xl" :disabled="printing" :loading="printing">
        <v-toolbar flat class="transparent">
          <v-icon left color="primary">print</v-icon>
          <h3>{{ $t("print") }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="showPrint = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-autocomplete
                  :items="methods"
                  outlined
                  v-model="method"
                  label="Method"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded @click="print" :disabled="!method" color="primary">
            <v-icon left>print</v-icon>
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import NdtForm from "@/views/Main/ndt/NdtForm.vue";
import methods from "./methods";
import OptionMenu from "./OptionMenu.vue";
import moment from "moment";

export default {
  components: { NdtForm, OptionMenu },
  data: () => ({
    show: false,
    loading: false,
    search: "",
    headers: [
      // { text: "Key", value: "key" },
      { text: "Timestamp", value: "timestamp", divider: true },
      { text: "Method", value: "method", divider: true },
      // { text: "Level", value: "level" },
      { text: "Date", value: "date", divider: true },
      { text: "Jobnumber", value: "jobnumber" },
      { text: "Part Number", value: "partnumber" },
      { text: "Serial Number", value: "serialnumber", divider: true },
      {
        text: "Work Performed",
        value: "workPerformed",
        width: 500,
        divider: true,
      },
      { text: "Duration (hrs)", value: "duration", divider: true },
      { text: "NDT Report", value: "ndtReport", divider: true },
      { text: "Actions", value: "actions", sortable: false },
    ],
    items: [],
    showPrint: false,
    methods,
    method: null,
    printing: false,
    height: 0,
    footerProps: {
      "items-per-page-options": [50, 100, 500, -1],
      "show-first-last-page": true,
    },
  }),
  created() {
    this.populate();
  },
  mounted() {
    this.onResize();
  },
  methods: {
    onResize() {
      const height = window.innerHeight - 230;
      this.height = !this.isMobile ? `${height}px` : `${height - 48}px`;
    },
    async populate() {
      this.loading = true;
      const { data } = await axios.get("/api/ndt", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("logbook-token")}`,
        },
      });
      this.items = data;
      this.loading = false;
    },

    async print() {
      this.printing = true;
      this.getUser();
      const url = `/documents/ndt/${this.getUser().employeeId}/${this.method}`;
      const { data } = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("logbook-token")}`,
        },
      });

      // const filename = `${this.getUser().employeeId}_${
      //   this.method
      // }_${new Date().toISOString()}.pdf`;
      const u = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      a.href = u;
      a.target = "_blank";
      // a.download = filename;
      a.click();
      a.remove();
      this.printing = false;

      // window.open(
      //   `/documents/ndt/${this.getUser().employeeId}/${this.method}`,
      //   "_blank"
      // );
    },
    getUser() {
      const user = sessionStorage.getItem("logbook-token");
      const me = JSON.parse(atob(user.split(".")[1]));
      return me;
    },
    showReport(item) {
      window.open(item.ndtReport, "_blank");
    },
    timestamp(t) {
      return moment(t).format("YYYY-MM-DD hh:mm:ss A");
    },
  },
  computed: {},
};
</script>

<style>
</style>

<i18n>
  {
    "en": {
      "entry": "Entry",
      "newEntry": "New logbook entry",
      "date": "Date of work",
      "location": "Location",
      "componentType": "Component type",
      "serialNumber": "Serial number",
      "taskType": "Task type",
      "experienceType": "Experience type",
      "ATA":"ATA",
      "duration": "Duration (hrs)",
      "operationPerformed": "Operation performed",
      "remark": "Remark",
      "privilege" : "Privilege"
    },
    "th": {
      "entry": "บันทึกที่",
      "newEntry": "สร้างบันทึกใหม่",
      "date": "วันที่ปฏิบัติงาน",
      "location": "สถานที่",
      "componentType": "ชิ้นส่วน",
      "serialNumber": "ซีเรียลนัมเบอร์",
      "taskType": "ประเภทกิจกรรม",
      "experienceType": "ประเภทประสบการณ์",
      "ATA":"ATA",
      "duration": "ระยะเวลา (ชั่วโมง)",
      "operationPerformed": "รายละเอียดงานที่ทำ",
      "remark": "หมายเหตุ",
      "privilege" : "สิทธิ"
    }
  }
</i18n>

<template>
  <v-dialog
    v-model="show"
    width="500"
    scrollable
    persistent
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card :loading="loading" :disabled="loading" class="rounded-xl">
      <v-toolbar :dense="dense" color="transparent" flat>
        <v-icon left color="primary">edit</v-icon>
        <h3>{{ $t("entry") }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:show', false)">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container fluid>
            <v-row :dense="dense">
              <v-col cols="12">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      :label="$t('date')"
                      append-icon="today"
                      readonly
                      outlined
                      :dense="dense"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row :dense="dense">
              <v-col cols="12">
                <JobnumberSearchField
                  outlined
                  :dense="dense"
                  hide-details
                  @found="found"
                  v-model="jobnumber"
                ></JobnumberSearchField>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  :label="$t('componentType')"
                  v-model="componentType"
                  outlined
                  :dense="dense"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  :label="$t('serialNumber')"
                  v-model="serialNumber"
                  outlined
                  :dense="dense"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :label="$t('ATA')"
                  v-model="ata"
                  outlined
                  :dense="dense"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row :dense="dense">
              <v-col cols="12">
                <v-text-field
                  :label="$t('location')"
                  v-model="location"
                  outlined
                  :dense="dense"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row :dense="dense">
              <v-col cols="6" md="8">
                <v-text-field
                  :label="$t('duration')"
                  v-model.number="duration"
                  outlined
                  :dense="dense"
                  hide-details
                  :suffix="$t('hours')"
                  :rules="[rules.required, rules.halfHour]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-btn color="secondary" block @click="timer = true" disabled>
                  <v-icon :left="!isMobile">timer</v-icon>
                  <span v-if="!isMobile">{{ $t("timer") }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row :dense="dense">
              <v-col>
                <Privilege :privilege.sync="privilege"></Privilege>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row :dense="dense">
              <v-col cols="6">
                <v-combobox
                  :label="$t('taskType')"
                  v-model="taskType"
                  :items="taskTypes"
                  outlined
                  :dense="dense"
                  hide-details
                  :rules="[rules.required]"
                ></v-combobox>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  :label="$t('experienceType')"
                  v-model="experienceType"
                  :items="experienceTypes"
                  outlined
                  :dense="dense"
                  hide-details
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  :label="$t('operationPerformed')"
                  v-model="operationPerformed"
                  outlined
                  :dense="dense"
                  hide-details
                  :rows="3"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  :label="$t('remark')"
                  v-model="remark"
                  outlined
                  :dense="dense"
                  hide-details
                  :rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn rounded text @click="cancel">
          <v-icon left>cancel</v-icon>
          {{ $t("cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded color="secondary" @click="post">
          <v-icon left>save</v-icon>
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import axios from "axios";
import JobnumberSearchField from "@/components/JobnumberSearchField.vue";
import Privilege from "@/components/Privilege.vue";

import { taskTypes } from "./taskTypes";

export default {
  components: { JobnumberSearchField, Privilege },
  props: { show: Boolean, item: Object },
  data: () => ({
    modal: false,
    loading: false,
    date: moment().format("YYYY-MM-DD"),
    location: "Airborne Support",
    componentType: "",
    serialNumber: "",
    rating: "",
    privilege: "",
    taskType: "",
    experienceType: "",
    ata: 0,
    operationPerformed: "",
    duration: 0,
    remark: "",
    jobnumber: "",
    rules: {
      required: (v) => !!v || "Required.",
      halfHour: (v) => v % 0.5 == 0 || "Invalid.",
    },
    dense: false,
  }),
  watch: {
    show(v) {
      if (v) {
        this.setData();
      }
    },
  },
  methods: {
    setData() {
      this.date = this.item.date;
      this.location = this.item.location;
      this.componentType = this.item.componentType;
      this.serialNumber = this.item.serialNumber;
      this.rating = this.item.rating;
      this.privilege = this.item.privilege;
      this.taskType = this.item.taskType;
      this.experienceType = this.item.experienceType;
      this.ata = this.item.ata;
      this.operationPerformed = this.item.operationPerformed;
      this.duration = this.item.duration;
      this.remark = this.item.remark;
      this.jobnumber = this.item.jobnumber;
    },
    async post() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      const post = {
        id: this.item.id,
        data: {
          date: this.date,
          location: this.location,
          componentType: this.componentType,
          serialNumber: this.serialNumber,
          rating: this.rating,
          privilege: this.privilege,
          taskType: this.taskType,
          experienceType: this.experienceType,
          ata: this.ata,
          operationPerformed: this.operationPerformed,
          duration: this.duration,
          remark: this.remark,
          jobnumber: this.jobnumber,
        },
      };
      await axios.put("/api/logbook", post, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("logbook-token")}`,
        },
      });
      this.loading = false;
      this.$emit("onUpdate");
      this.$emit("update:show", false);
    },
    found(e) {
      this.componentType = e.partname;
      this.serialNumber = toggleDOM(e.serialnumber1);
      this.jobnumber = e.jobnumber;
      this.ata = e.ata;

      function toggleDOM(val) {
        let arr = val.split("-");
        let b = arr[arr.length - 2];
        if (b != undefined && b.length == 1) {
          return arr[arr.length - 2] + "-" + arr[arr.length - 1];
        } else {
          return arr[arr.length - 1];
        }
      }
    },
    cancel() {
      // this.reset();
      this.$emit("update:show", false);
    },
  },
  computed: {
    experienceTypes() {
      return [
        { text: "Training", value: "Training" },
        { text: "Perform", value: "Perform" },
        { text: "Supervise", value: "Supervise" },
        { text: "CRS", value: "CRS" },
      ];
    },
    taskTypes() {
      return taskTypes;
    },
  },
};
</script>

<style>
</style>
<i18n>
  {
    "en": {
      "print": "Print",
      "message": "Please select period to print:",
      "all": "All time",
      "some": "Period",
      "from": "From",
      "until": "Until",
      "dateFormat": "YYYY-MM-DD"
    },
    "th": {
      "print": "พิมพ์บันทึกเวลา",
      "message": "กรุณาเลือกช่วงเวลาที่ต้องการพิมพ์:",
      "all": "ทั้งหมด",
      "some": "บางส่วน",
      "from": "วันเริ่ม",
      "until": "วันสิ้นสุด",
      "dateFormat": "ปปปป-ดด-วว (ค.ศ.)"
    }
  }
</i18n>

<template>
  <div>
    <v-btn
      v-if="!isMobile"
      @click="show = !show"
      :rounded="rounded"
      :text="text"
      :depressed="depressed"
      :outlined="outlined"
      :disabled="disabled"
    >
      <v-icon left>print</v-icon>
      {{ $t("print") }}
    </v-btn>
    <v-btn icon color="primary" v-if="isMobile" @click="show = !show">
      <v-icon>print</v-icon>
    </v-btn>
    <v-dialog v-model="show" width="400" persistent>
      <v-card class="rounded-xl" :loading="loading" :disabled="loading">
        <v-toolbar flat :dense="dense">
          <v-icon left color="primary">print</v-icon>
          <h3>{{ $t("print") }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="show = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row :dense="dense">
              <v-col>
                <Privilege
                  :privilege.sync="privilege"
                  :dense="dense"
                ></Privilege>
              </v-col>
              <v-col>
                <TaskTypePicker
                  v-model="taskType"
                  :dense="dense"
                ></TaskTypePicker>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-container fluid>
            <v-row :dense="dense">
              <v-col>
                <v-radio-group v-model="all" row class="mt-0">
                  <v-radio :label="$t('all')" :value="true"></v-radio>
                  <v-radio :label="$t('some')" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row :dense="dense">
              <v-col cols="6">
                <v-menu
                  ref="menuFrom"
                  v-model="menuFrom"
                  :close-on-content-click="false"
                  :return-value.sync="from"
                  offset-y
                  min-width="auto"
                  rounded="xl"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :dense="dense"
                      readonly
                      :label="$t('from')"
                      append-icon="today"
                      v-model="from"
                      hide-details
                      :disabled="all"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="from" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      rounded
                      color="primary"
                      @click="menuFrom = false"
                      >{{ $t("cancel") }}</v-btn
                    >
                    <v-btn
                      text
                      rounded
                      color="primary"
                      @click="$refs.menuFrom.save(from)"
                    >
                      {{ $t("confirm") }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="menuUntil"
                  v-model="menuUntil"
                  :close-on-content-click="false"
                  :return-value.sync="until"
                  offset-y
                  min-width="auto"
                  rounded="xl"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :dense="dense"
                      :label="$t('until')"
                      v-model="until"
                      hide-details
                      :disabled="all"
                      v-on="on"
                      v-bind="attrs"
                      readonly
                      append-icon="today"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="until" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      rounded
                      color="primary"
                      @click="menuUntil = false"
                      >{{ $t("cancel") }}</v-btn
                    >
                    <v-btn
                      text
                      rounded
                      color="primary"
                      @click="$refs.menuUntil.save(until)"
                    >
                      {{ $t("confirm") }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            @click="print"
            color="primary"
            :disabled="!allFieldsFilled"
          >
            <v-icon left>print</v-icon>
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

const pdfMake = require("pdfmake/build/pdfmake.js");
const pdfFonts = require("@/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  IBMPlexSansThai: {
    normal: "IBMPlexSansThai-Regular.ttf",
    bold: "IBMPlexSansThai-Bold.ttf",
    italics: "IBMPlexSansThai-Light.ttf",
  },
  Sarabun: {
    normal: "Sarabun-Regular.ttf",
    bold: "Sarabun-Bold.ttf",
    italics: "Sarabun-Italic.ttf",
  },
};

import Privilege from "@/components/Privilege";
import TaskTypePicker from "@/components/TaskTypePicker";

export default {
  components: { Privilege, TaskTypePicker },
  props: {
    disabled: Boolean,
    rounded: Boolean,
    text: Boolean,
    outlined: Boolean,
    depressed: Boolean,
  },
  data: () => ({
    show: false,
    all: true,
    from: "",
    until: "",
    menuFrom: false,
    menuUntil: false,
    privilege: "",
    taskType: "",
    dense: false,
    loading: false,
  }),
  methods: {
    async print() {
      this.loading = true;
      const token = sessionStorage.getItem("logbook-token");

      const bold = true;
      const user = JSON.parse(
        atob(sessionStorage.getItem("logbook-token").split(".")[1])
      );

      const post = {
        all: this.all,
        from: this.from,
        until: this.until,
        privilege: this.privilege,
        taskType: this.taskType,
      };
      const { data } = await axios.post("/api/logbook/print", post, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(data);
      let items = data.items;

      items.reverse();

      let total = 0;
      const body = items.map((x, index) => {
        total += x.duration;
        return [
          index + 1,
          x.date,
          x.location,
          x.componentType,
          x.serialNumber,
          x.ata,
          x.rating || "Part 145",
          x.privilege,
          x.taskType,
          x.experienceType,
          x.operationPerformed,
          x.jobnumber,
          x.remark,
          { text: x.duration.toFixed(2), alignment: "right" },
        ];
      });

      body.unshift([
        { text: "No", bold },
        { text: "Date", bold },
        { text: "Location", bold },
        { text: "A/C or Comp. Type", bold },
        { text: "S/C Reg. or Comp. S/N", bold },
        { text: "ATA", bold },
        { text: "Rating", bold },
        { text: "Privilege", bold },
        { text: "Task", bold },
        { text: "Activity", bold },
        { text: "Operation Performed", bold },
        { text: "Ref", bold },
        { text: "Remark", bold },
        { text: "Hrs", bold, alignment: "right" },
      ]);

      body.push([
        { text: "", bold },
        { text: "", bold },
        { text: "", bold },
        { text: "", bold },
        { text: "", bold },
        { text: "", bold },
        { text: "", bold },
        { text: "", bold },
        { text: "", bold },
        { text: "", bold },
        { text: "", bold },
        { text: "", bold },
        { text: "Total hours", bold, alignment: "right" },
        { text: total.toFixed(2), bold, alignment: "right" },
      ]);

      const timestamp = moment().format();

      const period = this.all ? "All" : this.from + " - " + this.until;

      const dd = {
        info: {
          title: `Experience Logbook - ${user.name} - ${timestamp}`,
          author: "Airborne Support Co., Ltd.",
        },
        pageSize: "A4",
        pageMargins: [20, 90, 20, 30],
        defaultStyle: {
          fontSize: 7,
          font: "Sarabun",
        },
        pageOrientation: "landscape",
        header: {
          layout: "noBorders",
          fontSize: 7,
          table: {
            widths: [50, "*", 50, "*", 50, "*", 50, "*"],
            body: [
              [
                {
                  text: "EXPERIENCE LOGBOOK",
                  bold,
                  fontSize: 14,
                  colSpan: 8,
                  alignment: "center",
                  margin: [0, 0, 0, 10],
                },
                {},
                {},
                {},
                {},
                {},
                {},
                {},
              ],
              [
                { text: "Name:" },
                { text: user.name, bold },
                { text: "Employee ID:" },
                { text: user.employeeId, bold },
                { text: "Position:" },
                { text: user.position, bold },
                { text: "Printed on:" },
                { text: timestamp, bold },
              ],
              [
                { text: "Privilege:" },
                { text: this.privilege || "All", bold },
                { text: "Period:" },
                { text: period, bold },
                { text: "Total entries:" },
                { text: items.length, bold },
                { text: "Total hours:" },
                { text: total.toFixed(2), bold },
              ],
            ],
          },
          margin: [20, 20, 20, 0],
        },
        footer: (currentPage, pageCount) => {
          return {
            layout: "noBorders",
            fontSize: 6,
            table: {
              widths: ["*", "*", "*"],
              body: [
                [
                  { text: "Airborne Support Co., Ltd." },
                  {
                    text: "The information in this document is correct at the time of printing.\nThis document is invalid without authorized signatures and may be digitally signed.",
                    alignment: "center",
                  },
                  {
                    text: `Page ${currentPage.toString()} of ${pageCount} `,
                    alignment: "right",
                  },
                ],
              ],
            },
            margin: [20, 0, 20, 20],
          };
        },
        watermark: {
          text: "Airborne Support",
          bold: true,
          opacity: 0.05,
          fontSize: 60,
          angle: 0,
        },
        content: [
          {
            layout: {
              hLineWidth: function (i, node) {
                if (i === node.table.headerRows || i === node.table.body.length)
                  return 2;
                if (i === node.table.body.length - 1) return 1;
                return 0;
              },
              vLineWidth: function () {
                return 0;
              },
              hLineColor: function () {
                return "black";
              },
              paddingLeft: function (i) {
                return i === 0 ? 0 : 8;
              },
              paddingRight: function (i, node) {
                return i === node.table.widths.length - 1 ? 0 : 8;
              },
            },
            table: {
              headerRows: 1,
              widths: [
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "*",
                "auto",
                80,
                "auto",
              ],
              body,
            },
          },
          // {
          //   layout: "noBorders",
          //   table: {
          //     widths: ["auto", "*"],
          //     body: [
          //       [
          //         {
          //           text: "- End of list -",
          //           colSpan: 2,
          //           alignment: "center",
          //           fontSize: 6,
          //         },
          //         {},
          //       ],
          //     ],
          //   },
          // },
        ],
      };

      pdfMake.createPdf(dd).open();
      this.show = false;
      this.loading = false;
    },
  },
  computed: {
    allFieldsFilled() {
      if (this.all) return true;
      return this.from && this.until;
    },
  },
};
</script>

<style>
</style>
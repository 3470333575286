<template>
  <div>
    <v-menu offset-y transition="slide-y-transition" rounded="xl">
      <template #activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="showEdit = true">
          <v-list-item-icon>
            <v-icon>edit</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Edit </v-list-item-title>
        </v-list-item>
        <v-list-item @click="del(item)">
          <v-list-item-icon>
            <v-icon>delete_outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Delete </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <ConfirmDialog ref="delDialog"></ConfirmDialog>
    <NdtForm
      :show.sync="showEdit"
      @updated="$emit('updated')"
      mode="edit"
      :logId="item.key"
    ></NdtForm>
  </div>
</template>

<script>
import axios from "axios";
import NdtForm from "@/views/Main/ndt/NdtForm.vue";
import methods from "./methods";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default {
  components: { ConfirmDialog, NdtForm },
  props: {
    item: Object,
  },
  data: () => ({
    methods,
    showEdit: false,
  }),
  methods: {
    async del(item) {
      const options = {
        validation: {
          required: false,
        },
      };
      if (!(await this.$refs.delDialog.confirmed(options))) return;
      await axios.delete(`/api/ndt/${item.key}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("logbook-token")}`,
        },
      });
      this.$emit("updated");
    },
  },
};
</script>

<style>
</style>
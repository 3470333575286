import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./assets/main.scss";

Vue.config.productionTip = false;

Vue.mixin({
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
});

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

<i18n>
  {
    "en": {
      "title": "Delete log entry",
      "message": "Are you sure to <b>permanently</b> delete this item entiry?",
      "submit": "Delete"
    },
    "th": {
      "title": "ลบบันทึกเวลา",
      "message": "คุณต้องการลบบันทึกเวลานี้หรือไม่ บันทึกที่ทำงานลบแล้วจะไม่สามารถแก้ไขหรือกู้กลับได้อีก",
      "submit": "ลบ"
    }
  }
</i18n>
<template>
  <v-dialog v-model="show" width="400" persistent :hide-overlay="hideOverlay">
    <v-card class="rounded-lg">
      <v-card-title>
        <h5>{{ $t("title") }}</h5>
      </v-card-title>
      <v-card-text>
        <p v-html="$t('message')"></p>
        <p v-if="validation.required">
          Enter
          <strong>{{ validation.answer }}</strong> (case sensitive) to proceed:
        </p>
        <v-text-field
          v-model="answer"
          autofocus
          v-if="validation.required"
          hide-details
          outlined
          dense
          autocomplete="off"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="confirm" :loading="loading">
          <v-icon left> delete </v-icon>
          {{ $t("submit") }}
        </v-btn>
        <v-btn color="primary" text @click="cancel">{{ $t("cancel") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AbsConfirmDialog",
  props: {
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      show: false,
      resolve: null,
      reject: null,
      validation: {
        required: false,
        answer: "",
      },
      answer: "",
    };
  },
  computed: {
    answerIsCorrect() {
      if (!this.validation.required) return true;
      if (this.validation.answer === this.answer) return true;
      return false;
    },
  },
  methods: {
    confirmed(options) {
      this.validateByUserInput = false;
      this.answer = "";
      this.validation.required = false;
      if (options) {
        if (options.validation) {
          this.validation.required = options.validation.required;
          this.validation.answer = options.validation.answer;
        }
      }
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancel() {
      this.resolve(false);
      this.show = false;
    },
    confirm() {
      this.resolve(true);
      if (this.delay) {
        this.loading = true;
      } else {
        this.show = false;
      }
    },
    manualClose() {
      this.loading = false;
      this.show = false;
    },
  },
};
</script>

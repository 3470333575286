import moment from "moment";
import axios from "axios";

function capitalize(text) {
  const a = text.split(" ");
  const v = a.map((x) => {
    return x.charAt(0).toUpperCase() + x.slice(1).toLowerCase();
  });
  return v.join(" ");
}

async function getData() {
  const token = sessionStorage.getItem("logbook-token");
  const { data } = await axios.get("/api/logbook", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const u = JSON.parse(atob(token.split(".")[1]));
  document.title = capitalize(u.name) + " - Logbook";
  data.forEach((x) => {
    x.timestamp = moment(x.timestamp).valueOf();
  });
  return data;
}

export { capitalize, getData };
